<template>
  <!--begin::Wrapper-->
  <div class="bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      id="kt_login_signup_form"
    >
      <!--begin::Heading-->
      <div class="mb-10 text-center">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">Create an Account</h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-bold fs-4">
          Already have an account?

          <router-link to="/login" class="link-primary fw-bolder">
            Sign in here
          </router-link>
        </div>
        <!--end::Link-->
      </div>
      <!--end::Heading-->

      <!--begin::Input group-->
      <div class="row fv-row mb-7 form-group">
        <!--begin::Col-->
        <div class="col-xl-6">
          <label class="form-label fw-bolder text-dark fs-6">First Name</label>
          <input
            class="form-control form-control-lg form-control-solid"
            type="text"
            placeholder="Firstname"
            name="firstname"
            ref="rfirstname"
            autocomplete="off"
          />
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-xl-6">
          <label class="form-label fw-bolder text-dark fs-6">Last Name</label>
          <input
            class="form-control form-control-lg form-control-solid"
            type="text"
            placeholder="Surname"
            name="surname"
            ref="rsurname"
            autocomplete="off"
          />
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-7 form-group">
        <label class="form-label fw-bolder text-dark fs-6">Email</label>
        <input
          class="form-control form-control-lg form-control-solid"
          type="email"
          placeholder="Email"
          name="email"
          ref="remail"
          autocomplete="off"
        />
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-10 fv-row form-group" data-kt-password-meter="true">
        <!--begin::Wrapper-->
        <div class="mb-1">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6"> Password </label>
          <!--end::Label-->

          <!--begin::Input wrapper-->
          <div class="position-relative mb-3">
            <input
              class="form-control form-control-lg form-control-solid"
              type="password"
              placeholder="Password"
              name="password"
              ref="rpassword"
              autocomplete="off"
            />
          </div>
          <!--end::Input wrapper-->
        </div>
        <!--end::Wrapper-->
        <!--begin::Hint-->
        <div class="text-muted">
          Minimum eight characters, at least one uppercase letter,<br />one
          lowercase letter, one number and one special character (@$!%*?&).
        </div>
        <!--end::Hint-->
      </div>
      <!--end::Input group--->

      <!--begin::Input group-->
      <div class="fv-row mb-5 form-group">
        <label class="form-label fw-bolder text-dark fs-6"
          >Confirm Password</label
        >
        <input
          class="form-control form-control-lg form-control-solid"
          type="password"
          placeholder="Confirm password"
          name="cpassword"
          ref="cpassword"
          autocomplete="off"
        />
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10 form-group">
        <label class="form-check form-check-custom form-check-solid">
          <input type="checkbox" ref="ragree" name="agree" />
          <span class="form-check-label fw-bold text-gray-700 fs-6">
            I Agree &
            <a href="#" class="ms-1 link-primary">Terms and conditions</a>.
          </span>
        </label>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <v-btn
          :loading="loading.signup"
          @click="signup"
          color="primary"
          large
          elevation="0"
          ref="kt_login_signup_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
          style="background-color: #3699FF;width:150px;"
        >
          Submit
        </v-btn>
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>
<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "signup",
  data() {
    return {
      fv: null,
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      loading: state => state.auth.loading,
      message: state => state.message.message
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.VUE_APP_BASE_URL +
        process.env.BASE_URL +
        "media/svg/illustrations/login-visual-6.svg"
      );
    }
  },
  mounted() {
    this.$refs.remail.value = this.$route.query.mail
      ? this.$route.query.mail
      : "";
    const signup_form = KTUtil.getById("kt_login_signup_form");
    this.fv = formValidation(signup_form, {
      fields: {
        firstname: {
          validators: {
            notEmpty: {
              message: "Firstname is required"
            }
          }
        },
        surname: {
          validators: {
            notEmpty: {
              message: "Surname is required"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            },
            stringLength: {
              min: 8,
              message: "The password must have at least 8 characters"
            },
            regexp: {
              regexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message: "Use characters with a mix of letters"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },
  methods: {
    signup() {
      const firstname = this.$refs.rfirstname.value;
      const surname = this.$refs.rsurname.value;
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;

      this.fv.validate().then(isValid => {
        if (isValid === "Valid") {
          // clear existing errors
          this.$store.dispatch(LOGOUT);

          // set spinner to submit button
          this.loading.signup = true;

          // send register request
          this.$store
            .dispatch(REGISTER, {
              firstname: firstname,
              surname: surname,
              email: email,
              password: password
            })
            .then(() => {
              this.loading.signup = false;
              this.$router.push({ name: "dashboard" });
            });
        } else {
          Swal.fire({
            title: "",
            text: "Please, provide correct data!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        }
      });
    }
  }
};
</script>
